<template>
  <BOverlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <!-- Form: DOCENTE -->
    DOCENTE
    <BRow>

      <!-- NOMBRES -->
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup
          label="Título *"
          label-for="nombres"
        >
          <BFormInput
            id="nombres"
            v-model="docente.nombres"
            placeholder="Ingrese los nombres"
            :state="v$.docente.nombres.$error === true
            ? false
            : null"
            @blur.native="v$.docente.nombres.$touch"
          />
          <!-- Validaciones -->
          <BFormInvalidFeedback
            v-if="v$.docente.nombres.$error"
            id="nombresInfo"
          >
            <p v-for="error of v$.docente.nombres.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <!-- PRIMER APELLIDO -->
      <BCol
        cols="12"
        md="3"
      >
        <BFormGroup
          label="Primer Apellido *"
          label-for="primerApellido"
        >
          <BFormInput
            id="primerApellido"
            v-model="docente.primerApellido"
            placeholder="Ingrese los primerApellido"
            :state="v$.docente.primerApellido.$error === true
            ? false
            : null"
            @blur.native="v$.docente.primerApellido.$touch"
          />
          <!-- Validaciones -->
          <BFormInvalidFeedback
            v-if="v$.docente.primerApellido.$error"
            id="primerApellidoInfo"
          >
            <p v-for="error of v$.docente.primerApellido.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <!-- SEGUNDO APELLIDO -->
      <BCol
        cols="12"
        md="3"
      >
        <BFormGroup
          label="Segundo Apellido *"
          label-for="segundoApellido"
        >
          <BFormInput
            id="segundoApellido"
            v-model="docente.segundoApellido"
            placeholder="Ingrese los segundoApellido"
            :state="v$.docente.segundoApellido.$error === true
            ? false
            : null"
            @blur.native="v$.docente.segundoApellido.$touch"
          />
          <!-- Validaciones -->
          <BFormInvalidFeedback
            v-if="v$.docente.segundoApellido.$error"
            id="segundoApellidoInfo"
          >
            <p v-for="error of v$.docente.segundoApellido.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <!-- Teléfono -->
      <BCol
        cols="12"
        md="2"
      >
        <BFormGroup
          label="Celular"
          label-for="celular"
        >
          <BFormInput
            id="celular"
            v-model="docente.celular"
            placeholder="Ingrese los celular"
            :state="v$.docente.celular.$error === true
            ? false
            : null"
            @blur.native="v$.docente.celular.$touch"
          />
          <!-- Validaciones -->
          <BFormInvalidFeedback
            v-if="v$.docente.celular.$error"
            id="celularInfo"
          >
            <p v-for="error of v$.docente.celular.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <!-- CORREO -->
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup
          label="Correo *"
          label-for="correo"
        >
          <BFormInput
            id="correo"
            v-model="docente.correo"
            placeholder="Ingrese los correo"
            :state="v$.docente.correo.$error === true
            ? false
            : null"
            @blur.native="v$.docente.correo.$touch"
          />
          <!-- Validaciones -->
          <BFormInvalidFeedback
            v-if="v$.docente.correo.$error"
            id="correoInfo"
          >
            <p v-for="error of v$.docente.correo.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

    </BRow>

    <colLinea />
  </BOverlay>
</template>

<script>

// Etiquetas //
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BCard, BFormSelect,
} from 'bootstrap-vue'

// Validaciones //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes //
import colLinea from '../../../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    // vSelect,
    BOverlay,
    BCard,
    BFormSelect,

    colLinea,
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      cargando: true,
      docente: [],
      // required,
      // email,
    }
  },
  props: {
  },
  validations() {
    return {
      docente: {
        rut: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 9 caracteres.', maxLength(9)),
        },
        nombres: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primerApellido: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundoApellido: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        correo: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),

          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
        },
        idRol: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  methods: {
    submitOption() {
      this.v$.docente.$touch()
      // if (!this.v$.docente.$invalid) {
      //   this.$emit('processForm', this.docente)
      // }
    },
  },
}
</script>
