var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BOverlay',{attrs:{"show":!_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_vm._v(" DOCENTE "),_c('BRow',[_c('BCol',{attrs:{"cols":"12","md":"4"}},[_c('BFormGroup',{attrs:{"label":"Título *","label-for":"nombres"}},[_c('BFormInput',{attrs:{"id":"nombres","placeholder":"Ingrese los nombres","state":_vm.v$.docente.nombres.$error === true
          ? false
          : null},nativeOn:{"blur":function($event){return _vm.v$.docente.nombres.$touch($event)}},model:{value:(_vm.docente.nombres),callback:function ($$v) {_vm.$set(_vm.docente, "nombres", $$v)},expression:"docente.nombres"}}),(_vm.v$.docente.nombres.$error)?_c('BFormInvalidFeedback',{attrs:{"id":"nombresInfo"}},_vm._l((_vm.v$.docente.nombres.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":"12","md":"3"}},[_c('BFormGroup',{attrs:{"label":"Primer Apellido *","label-for":"primerApellido"}},[_c('BFormInput',{attrs:{"id":"primerApellido","placeholder":"Ingrese los primerApellido","state":_vm.v$.docente.primerApellido.$error === true
          ? false
          : null},nativeOn:{"blur":function($event){return _vm.v$.docente.primerApellido.$touch($event)}},model:{value:(_vm.docente.primerApellido),callback:function ($$v) {_vm.$set(_vm.docente, "primerApellido", $$v)},expression:"docente.primerApellido"}}),(_vm.v$.docente.primerApellido.$error)?_c('BFormInvalidFeedback',{attrs:{"id":"primerApellidoInfo"}},_vm._l((_vm.v$.docente.primerApellido.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":"12","md":"3"}},[_c('BFormGroup',{attrs:{"label":"Segundo Apellido *","label-for":"segundoApellido"}},[_c('BFormInput',{attrs:{"id":"segundoApellido","placeholder":"Ingrese los segundoApellido","state":_vm.v$.docente.segundoApellido.$error === true
          ? false
          : null},nativeOn:{"blur":function($event){return _vm.v$.docente.segundoApellido.$touch($event)}},model:{value:(_vm.docente.segundoApellido),callback:function ($$v) {_vm.$set(_vm.docente, "segundoApellido", $$v)},expression:"docente.segundoApellido"}}),(_vm.v$.docente.segundoApellido.$error)?_c('BFormInvalidFeedback',{attrs:{"id":"segundoApellidoInfo"}},_vm._l((_vm.v$.docente.segundoApellido.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":"12","md":"2"}},[_c('BFormGroup',{attrs:{"label":"Celular","label-for":"celular"}},[_c('BFormInput',{attrs:{"id":"celular","placeholder":"Ingrese los celular","state":_vm.v$.docente.celular.$error === true
          ? false
          : null},nativeOn:{"blur":function($event){return _vm.v$.docente.celular.$touch($event)}},model:{value:(_vm.docente.celular),callback:function ($$v) {_vm.$set(_vm.docente, "celular", $$v)},expression:"docente.celular"}}),(_vm.v$.docente.celular.$error)?_c('BFormInvalidFeedback',{attrs:{"id":"celularInfo"}},_vm._l((_vm.v$.docente.celular.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":"12","md":"4"}},[_c('BFormGroup',{attrs:{"label":"Correo *","label-for":"correo"}},[_c('BFormInput',{attrs:{"id":"correo","placeholder":"Ingrese los correo","state":_vm.v$.docente.correo.$error === true
          ? false
          : null},nativeOn:{"blur":function($event){return _vm.v$.docente.correo.$touch($event)}},model:{value:(_vm.docente.correo),callback:function ($$v) {_vm.$set(_vm.docente, "correo", $$v)},expression:"docente.correo"}}),(_vm.v$.docente.correo.$error)?_c('BFormInvalidFeedback',{attrs:{"id":"correoInfo"}},_vm._l((_vm.v$.docente.correo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea')],1)}
var staticRenderFns = []

export { render, staticRenderFns }